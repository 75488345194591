import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Layout } from 'layouts';
import GridContainer from '../components/Grid/GridContainer.jsx';
import GridItem from '../components/Grid/GridItem.jsx';
import withStyles from '@material-ui/core/styles/withStyles';
import dashboardStyle from '../assets/jss/styles/components/dashboardStyle';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import Box from '@material-ui/core/Box';
import { FormControlLabel } from '@material-ui/core';
import Helmet from 'react-helmet';
import Constants from '../../static/Constants';
import projectStyles from '../styles/publications-styles';
import { useTranslation } from 'react-i18next';
import GridView from '../components/analytics/GridView';
import getHeaderLInks from '../shared/headerLinks.js';
import filterOfflinePublications from '../shared/filterOfflinePublications';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

Transition.displayName = 'Transition';

const { buttonApply, clearSection } = projectStyles;

const getQueryStringParams = query => {
  return query
    ? (/^[?#]/.test(query) ? query.slice(1) : query)
        .split('&')
        .reduce((acc, param) => {
          let [keyValue, ...values] = param.split('::');
          let [key, value] = keyValue.split('=');
          if (value) {
            const paramValues = [value, ...values];
            acc[key] = (paramValues || []).map(value =>
              decodeURIComponent(value.replace(/\+/g, ' '))
            );
            return acc;
          }
          acc[key] = undefined;
          return [];
        }, {})
    : {};
};

const Analytics = ({ ...props }) => {
  const { t } = useTranslation();
  const { pageContext } = props;

  const data = pageContext.pageData ? pageContext.pageData : null;
  if (!data) {
    return;
  }

  const helmetLink = `${Constants.appUrl}/projects`;
  const { location, navigate } = props;

  const programLink = pageContext.programUrl;

  const headerLinks = getHeaderLInks('Value Chains', programLink, data.noLinks);

  const logos = pageContext.logos || [];

  const { programName, analytic } = (data || {}).node || {};

  const { headerLogo, headerLogoScroll, screenName } = (data || {}).node || {};

  // const [searchString, setSearchString] = useState('');

  const [filteredAnalytics, setfilteredAnalytics] = useState([]);
  const [searchCriteria, setSearchCriteria] = useState(
    getQueryStringParams(location.search || '')
  );

  useEffect(() => {
    setfilteredAnalytics([]);
    setSearchCriteria(getQueryStringParams(location.search || ''));
  }, [location.search]);

  useEffect(() => {
    getfilteredAnalytics();
  }, [searchCriteria]);

  const getfilteredAnalytics = () => {
    const filterParams = prepareFilterMasterParams(searchCriteria);
    const filteredAnalytics =
      filterOfflinePublications(
        analytic || [],
        { ...filterParams, Filters: searchCriteria },
        [],
        []
      ) || [];
    setfilteredAnalytics(filteredAnalytics);
  };

  const prepareFilterMasterParams = params => {
    let filters = [];
    let filterMasterParams = {};
    if (params) {
      if (Object.keys(params).find(key => key === 'Favorites')) {
        const val = {
          Name: 'Favorites',
          Value: JSON.parse(
            localStorage.getItem(
              `${data.node.programCode ? data.node.programCode : ''}Favorites`
            )
          ),
        };
        filters.push(val);
      } else {
        Object.keys(params).map(function(key) {
          let val = {};
          if (key === 'search') {
            val = {
              Name: key,
              Value: (params[key] || [''])[0],
            };
          } else {
            if (params[key].length > 1) {
              val = {
                Name: key === 'InvestmentType' ? 'PROJECTOPPORTUNITYTYPE' : key,
                Value: params[key].map(Number),
              };
            } else if (params[key].length > 0) {
              val = {
                Name: key === 'InvestmentType' ? 'PROJECTOPPORTUNITYTYPE' : key,
                Value: [parseInt(params[key])],
              };
            }
          }
          filters.push(val);
        });
      }

      filterMasterParams = {
        PageNumber: 0,
        PageSize: 0,
        SortBy: '',
        SortOrder: '',
        Filters: filters,
      };
    }
    return filterMasterParams;
  };

  // const filterClick = (action, inputQuery = null) => () => {
  //     let params = {};
  //     let queryString = '';
  //     if (action === 'filter' || action === 'clearSearch') {
  //         params = {
  //             search: searchString && action === 'filter' ? searchString : '',
  //         };
  //         Object.keys(params).forEach(key => {
  //             if (!params[key] || params[key].length === 0) {
  //                 delete params[key];
  //             }
  //         });

  //         queryString =
  //             '?' +
  //             Object.keys(params)
  //                 .map(key =>
  //                     key === 'search'
  //                         ? `${key}=${params[key]}`
  //                         : `${key}=${params[key].reduce((acc, value) => {
  //                             return acc === '' ? `${acc}${value}` : `${acc}::${value}`;
  //                         }, '')}`
  //                 )
  //                 .join('&');
  //     }
  //     navigate(`${location.pathname}${inputQuery || queryString}`, {});
  // };

  // const onSectorsSearch = action => {
  //     action
  //         ? searchString.length === 0
  //             ? location.href &&
  //             location.href.indexOf('?') > 0 &&
  //             filterClick('clearSearch')()
  //             : searchString.length > 2 && filterClick('filter')()
  //         : location.href &&
  //         location.href.indexOf('?') > 0 &&
  //         filterClick('clearSearch')();
  // };

  return (
    <Layout
      programLink={programLink}
      title={t('analytics_title')}
      content={[t('analytics_subtitle')]}
      imageName={'bg-analytics.png'}
      contentAlignmentStyle={'textCenter'}
      headerLinks={headerLinks}
      logos={logos}
      headerLogo={headerLogo}
      headerLogoScroll={headerLogoScroll}
      visitedLinks={[{ url: '/', key: 'home', name: 'Home' }]}
      currentPage={{ key: 'analytics' }}
      screenName={screenName}
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>{t('Analytics')}</title>
        <link rel="canonical" href={helmetLink} />
      </Helmet>
      <div className="container">
        <GridContainer className="projects-container">
          <GridContainer>
            <GridItem md={12}>
              <GridView
                totalProjects={null}
                gridData={filteredAnalytics}
                onPageNumberChange={() => {}}
                onPageSizeChange={() => {}}
                fallback={() => {}}
                isLoading={false}
                programLink={programLink}
                programCode={programName}
                handleFavoriteProjects={() => {}}
                favoriteProjects={[]}
              />
            </GridItem>
          </GridContainer>
        </GridContainer>
      </div>
    </Layout>
  );
};

export default withStyles(dashboardStyle)(Analytics);

const ClearFilterSection = ({ handleClick }) => {
  return (
    <FormControlLabel
      aria-label="Acknowledge"
      css={clearSection}
      onClick={event => event.stopPropagation()}
      onFocus={event => event.stopPropagation()}
      control={
        <IconButton
          size="small"
          color="inherit"
          onClick={handleClick}
          aria-label="close"
        >
          <CloseIcon />
        </IconButton>
      }
    />
  );
};

const ApplyFilterSection = ({ handleClick, updateElement }) => {
  const { t } = useTranslation();
  return (
    <FormControlLabel
      aria-label="Acknowledge"
      css={clearSection}
      onClick={() => (updateElement ? updateElement(false) : '')}
      onFocus={event => event.stopPropagation()}
      control={
        <button css={buttonApply} onClick={handleClick}>
          {t('apply')}
        </button>
      }
    />
  );
};

ClearFilterSection.propTypes = {
  handleClick: PropTypes.func,
};

ApplyFilterSection.propTypes = {
  handleClick: PropTypes.func,
  updateElement: PropTypes.func,
};
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

Analytics.propTypes = {
  classes: PropTypes.any,
  location: PropTypes.object,
  navigate: PropTypes.func,
  pageContext: PropTypes.any,
};
